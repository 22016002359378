var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{attrs:{"color":"primary"}},[_c('h4',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('message.update-item-recomend-title'))+" ")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('FilterEditRecomend'),(!_vm.isLength)?_c('v-pagination',{attrs:{"length":_vm.lengthPage,"total-visible":7,"depressed":""},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('v-data-table',{staticClass:"elavation-0",attrs:{"headers":_vm.headers,"items":_vm.itemList,"disable-sort":"","server-items-length":_vm.itemTotal,"options":_vm.option,"page":_vm.currentPage,"items-per-page":_vm.itemPerPage,"footer-props":{'items-per-page-options':[_vm.itemPerPage]},"hide-default-footer":true,"hide-default-header":true},on:{"update:options":function($event){_vm.option=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('th',{staticClass:"custom-th text-center"},[_vm._v(" "+_vm._s(props.headers[0].text)+" ")]),_c('th',{staticClass:"custom-th text-center"},[_vm._v(" "+_vm._s(props.headers[1].text)+" ")]),_c('th',{staticClass:"custom-th text-center"},[_vm._v(" "+_vm._s(props.headers[2].text)+" ")]),_c('th',{staticClass:"custom-th text-center",staticStyle:{"width":"25%"}},[_vm._v(" "+_vm._s(props.headers[3].text)+" ")]),_c('th',{staticClass:"custom-th text-center"},[_vm._v(" "+_vm._s(props.headers[4].text)+" ")]),_c('th',{staticClass:"custom-th text-center"},[_vm._v(" "+_vm._s(props.headers[5].text)+" ")]),_c('th',{staticClass:"custom-th text-center"},[_vm._v(" "+_vm._s(props.headers[6].text)+" ")]),_c('th',{staticClass:"custom-th text-center"},[_vm._v(" "+_vm._s(props.headers[7].text)+" ")])]}},{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"image-url-class",staticStyle:{"width":"100px","height":"auto"},attrs:{"lazy-src":item.image_url ? item.image_url : _vm.notFound,"src":item.image_url ? item.image_url : _vm.notFound}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.has_recommended == 1)?_c('div',[_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"color":'warning'},on:{"click":function($event){return _vm.recomendItem(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete-sweep ")]),_vm._v(" "+_vm._s(_vm.$t('message.delete-recomend'))+" ")],1)],1):_c('div',[_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"color":'default'},on:{"click":function($event){return _vm.recomendItem(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-thumb-up ")]),_vm._v(" "+_vm._s(_vm.$t('message.add-recomend'))+" ")],1)],1)]}},{key:"item.item_recom_id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.has_recommended == 1)?_c('div',[(index != 0 || _vm.currentPage != 1)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-icon',{attrs:{"size":"35"},on:{"click":function($event){return _vm.upRecomend(item)}}},[_vm._v(" mdi-arrow-up-thin ")])],1):_vm._e(),(item.id != _vm.itemLastPage.id)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-icon',{attrs:{"size":"35"},on:{"click":function($event){return _vm.downRecomend(item)}}},[_vm._v(" mdi-arrow-down-thin ")])],1):_vm._e()]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }