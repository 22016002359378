<template>
  <v-container>
    <v-form @submit.prevent="submitFilterItem">
      <v-row no-gutters align="center" justify="space-between">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="3" xs="6">
              <v-label class="p-0 m-0">{{ $t("message.item-code") }}</v-label>
              <v-text-field
                v-model="filter.item_code"
                class="mt-1 font-size filter-input-class"
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" xs="6">
              <v-label class="p-0 m-0">{{ $t("message.shop") }}</v-label>
              <v-autocomplete
                v-model="filter.shopId"
                :items="storeListData"
                item-text="store_name"
                item-value="id"
                class="mt-1 font-size filter-input-class"
                clearable
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" xs="6">
              <v-label class="p-0 m-0">{{ $t("message.name") }}</v-label>
              <v-text-field
                v-model="filter.name"
                class="mt-1 font-size filter-input-class"
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" xs="4">
              <span class="pt-3" style="color: grey">{{$t("message.label-price-range")}}</span>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    type="number"
                    class="mt-1"
                    v-model="filter.priceStart"
                    outlined
                    clearable
                  />
                </v-col>
                <v-col class="minus-amount">
                  <v-icon class="icon-tilde-class">mdi-tilde</v-icon>
                </v-col>
                <v-col>
                  <v-text-field
                    type="number"
                    class="mt-1 font-size filter-input-class"
                    v-model="filter.priceEnd"
                    outlined
                    clearable
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="3" xs="6">
              <v-label class="p-0 m-0">{{ $t("message.status") }}</v-label>
              <v-select
                class="mt-1 font-size filter-input-class"
                outlined
                clearable
                v-model="filter.selectStatus"
                :items="statusItems"
                item-text="text"
                item-value="id"
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" xs="6">
              <v-label class="p-0 m-0">{{ $t("message.category") }}</v-label>
              <v-autocomplete
                class="mt-1 font-size filter-input-class"
                outlined
                clearable
                v-model="filter.categoryId"
                :items="categoryList"
                item-text="category_name"
                item-value="id"
                persistent-hint
                auto-select-first
                :menu-props="{ maxWidth: '50%' }"
              >
                <template v-slot:item="{ item }">
                  <span style="font-size:80%;color:rgba(0,0,0,.45)!important;">{{item.prefix}}</span>{{item.category_name}}
                </template>
              </v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" md="3" xs="6">
              <v-label class="p-0 m-0">{{ $t("message.label-filter-recomendation") }}</v-label>
              <v-select
                class="mt-1 font-size filter-input-class"
                outlined
                v-model="filter.has_recommended"
                :items="hasRecommendation"
                item-text="text"
                item-value="id"
                persistent-hint
                return-object
                clearable
              ></v-select>
            </v-col> -->
          </v-row>
        </v-col>
        <v-col class="" cols="6" md="1" xs="6">
          <v-btn type="submit" @click.prevent="submitFilterItem" rounded color="primary">
            <v-icon left>
              {{ iconSearch }}
            </v-icon>
            {{ $t("message.search") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
export default {
  name: "FilterEditRecomend",
  data: () => ({
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    valueWhenIsEmpty: "",
    iconSearch: mdiMagnify,
    filter: {
      categoryId: "",
      priceStart: "",
      // has_recommended: { id: "1", value: "recommended" },
      priceEnd: "",
      selectStatus: "",
      name: "",
      item_code: "",
      shopId: "",
    },
    storeList: [],
    // tempStore: [],
  }),
  computed: {
    categoryList() {
      return this.$store.state.itemList_module.categoryList;
    },
    storeListData() {
      return this.$store.state.shop_module.shopAllResult;
    },
    statusItems() {
      return [
        { id: "1", text: this.$t("message.active") },
        { id: "0", text: this.$t("message.inactive") },
      ];
    },
    // hasRecommendation() {
    //   return [
    //     { id: "1", text: this.$t("message.label-recomendation"), value: "recommended" },
    //     { id: "0", text: this.$t("message.label-unrecomendation"), value: "unrecommended" }
    //   ]
    // }
  },
  created() {
    this.dispatchStoreList();
    this.dispatchCategoryList();
    this.initFilter();
    // this.dispatchFilter();
  },
  methods: {
    dispatchCategoryList() {
      this.$store.dispatch("itemList_module/categoryList");
    },
    dispatchStoreList() {
      this.$store.dispatch("shop_module/fetchShopAll");
    },
    submitFilterItem() {
      // this.dispatchFilter()
      this.$store.dispatch("itemRecomend_module/fetchItemList", {
        item_code: this.filter.item_code === null ? "" : this.filter.item_code,
        shopId: this.filter.shopId === null ? "" : this.filter.shopId,
        name: this.filter.name === null ? "" : this.filter.name,
        statusId: this.filter.selectStatus === null ? "" : this.filter.selectStatus,
        priceStart: this.filter.priceStart === null ? "" : this.filter.priceStart,
        priceEnd: this.filter.priceEnd === null ? "" : this.filter.priceEnd,
        categoryId: this.filter.categoryId === null ? "" : this.filter.categoryId,
        // has_recommended: this.filter.has_recommended === null ? "" : this.filter.has_recommended.id,
        page: 1,
      });
    },
    // async dispatchFilter() {
    //   await this.$store.dispatch("itemRecomend_module/fetchItemList", {
    //     item_code: this.filter.item_code === null ? "" : this.filter.item_code,
    //     shopId: this.filter.shopId === null ? "" : this.filter.shopId,
    //     name: this.filter.name === null ? "" : this.filter.name,
    //     statusId: this.filter.selectStatus.id === null ? "" : this.filter.selectStatus.id,
    //     priceStart: this.filter.priceStart === null ? "" : this.filter.priceStart,
    //     priceEnd: this.filter.priceEnd === null ? "" : this.filter.priceEnd,
    //     categoryId: this.filter.categoryId === null ? "" : this.filter.categoryId,
    //     // has_recommended: this.filter.has_recommended === null ? "" : this.filter.has_recommended.id,
    //     page: 1,
    //   });
    // },
    async initFilter() {
      // this.dispatchFilter()
      this.filter.item_code = this.$store.state.itemRecomend_module.filter.item_code;
      this.filter.shopId = this.$store.state.itemRecomend_module.filter.shopId;
      this.filter.name = this.$store.state.itemRecomend_module.filter.name;
      this.filter.selectStatus = this.$store.state.itemRecomend_module.filter.selectedStatus;
      this.filter.priceStart = this.$store.state.itemRecomend_module.filter.priceStart;
      this.filter.priceEnd = this.$store.state.itemRecomend_module.filter.priceEnd;
      this.filter.categoryId = this.$store.state.itemRecomend_module.filter.categoryId;
      this.filter.shopId = this.$store.state.itemRecomend_module.filter.shopId;
      
    }
  },
};
</script>

<style>
.filter-input-class .v-input__slot .v-text-field__slot input{
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot .v-select__selections input {
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot input {
  padding: 8px 0px 8px 0px;
}
.font-size {
  font-size: 12px !important;
}
.btn-search {
  border-radius: 20px;
}
/* .v-input .v-input__control .v-text-field__details {
} */
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
.minus-amount {
  max-width: fit-content;
}
.minus-amount .icon-tilde-class {
  font-size: 14px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 8px;
}
</style>
