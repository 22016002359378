<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title color="primary">
        <h4 color="primary">
          {{$t('message.update-item-recomend-title')}}
        </h4>
      </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <!-- <template>
        <v-btn color="grey" @click="$router.go(-1)">
            {{$t('message.back-button')}}
        </v-btn>
      </template> -->
    </v-toolbar>

    <FilterEditRecomend />
    <!-- data table -->

    <v-pagination
      v-if="!isLength"
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
      depressed
    ></v-pagination>

    <v-data-table
      :headers="headers"
      :items="itemList"
      disable-sort
      class="elavation-0"
      :server-items-length="itemTotal"
      :options.sync="option"
      :page="currentPage"
      :items-per-page="itemPerPage"
      :footer-props="{'items-per-page-options':[itemPerPage]}"
      :hide-default-footer="true"
      :hide-default-header="true"
    >

     <template v-slot:header="{ props }" style="min-height: 50px">
        <th class="custom-th text-center">
          {{ props.headers[0].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[1].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[2].text }}
        </th>
        <th class="custom-th text-center" style="width: 25%;">
          {{ props.headers[3].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[4].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[5].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[6].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[7].text }}
        </th>
      </template>

      <!-- imageproduct itemlist loop -->
      <template v-slot:[`item.image_url`]="{ item }">
        <v-img
          :lazy-src="item.image_url ? item.image_url : notFound"
          :src="item.image_url ? item.image_url : notFound"
          class="image-url-class"
          style="width:100px; height: auto;"
        ></v-img>
      </template>

      <!-- action button itemlist loop -->
      <template v-slot:[`item.actions`]="{ item }">
        <div v-if="item.has_recommended == 1">
          <v-btn :color="'warning'" style="font-size: 10px" @click="recomendItem(item)">
            <v-icon left>
              mdi-delete-sweep
            </v-icon>
            {{$t('message.delete-recomend')}}
          </v-btn>
        </div>
        <div v-else>
          <v-btn :color="'default'" style="font-size: 10px" @click="recomendItem(item)">
            <v-icon left>
              mdi-thumb-up
            </v-icon>
            {{$t('message.add-recomend')}}
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.item_recom_id`]="{ item,index }">
        <div v-if="item.has_recommended == 1">
          <div v-if="index != 0 || currentPage != 1" class="d-flex justify-center align-center">
            <v-icon
              size="35" 
              @click="upRecomend(item)">
              mdi-arrow-up-thin
            </v-icon>
          </div>
          <div v-if="item.id != itemLastPage.id" class="d-flex justify-center align-center">
            <v-icon
              size="35" 
              @click="downRecomend(item)">
              mdi-arrow-down-thin
            </v-icon>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import FilterEditRecomend from "@/components/FilterEditRecomend";
import moneyFormat from '../helpers/moneyFormat';

export default {
  name: "EditRecomend",
  components: {
    FilterEditRecomend
  },
  data: () => ({
    notFound: require('../assets/image-not-found.png'),
    option: {},
    isLoading: false,
    message: {},
    page: 1
  }),

  watch: {
    itemList: {
      handler() {
        this.page = parseInt(this.$store.state.itemRecomend_module.page)
      },
      deep: true
    }
  },

  methods: {
    async upRecomend(item) {
      this.isLoading = true
      await this.$store.dispatch("itemRecomend_module/putSetUpItemRecomend", {
        item_recom_id: item.item_recom_id
      })
      this.isLoading = false
    },
    async downRecomend(item) {
      this.isLoading = true
      await this.$store.dispatch("itemRecomend_module/putSetDownItemRecomend", {
        item_recom_id: item.item_recom_id
      })
      this.isLoading = false
    },
    changePage(event) {
      return this.$store.dispatch("itemRecomend_module/fetchItemList", {
        page: event,
      });
    },
    fetchItem() {
      // const {page} = this.option
      let pageInit = parseInt(this.$store.state.itemRecomend_module.page);
      this.page = parseInt(this.$store.state.itemRecomend_module.page)
      this.$store.dispatch('itemRecomend_module/fetchItemList', { page : pageInit})
    },
    async recomendItem(item) {
      this.isLoading = true
      if(!item.has_recommended){
        //add recomendation
        this.message.title = this.$t("message.title-addedItem-recomend-success-message")
        let resp = await this.$store.dispatch('itemRecomend_module/addItemRecomend', {
          'id': item.id,
          'storeId': item.store_id,
          message: {
            title: this.message.title
          }
        })
      }else{
        //delete recomendation
        this.message.title = this.$t("message.title-deleteItem-recomend-success-message")
        let resp = await this.$store.dispatch('itemRecomend_module/deleteItemRecomend', {
          'recomend_id': item.item_recom_id,
          message: {
            title: this.message.title
          }
        })
      }
      this.isLoading = false
      this.fetchItem();
    }
  },
  created() {
    this.fetchItem();
  },
  mounted(){
    this.$store.dispatch('itemRecomend_module/clearFilter')
  },
  computed: {
    isLength() {
      return this.$store.state.itemRecomend_module.is_length;
    },
    lengthPage() {
      return this.$store.state.itemRecomend_module.length_page;
    },

    itemList() {
      let itemListRecom = this.$store.state.itemRecomend_module.itemList
      if (!itemListRecom) return 0
      else itemListRecom.map(el => {
        el.price = moneyFormat(el.price)
      })
      return itemListRecom
    },
    itemTotal() {
      let resp = this.$store.state.itemRecomend_module.itemHeader
      if(resp.total === undefined) {
        return 0
      }
      return resp.total
    },
    itemPerPage() {
      let resp = this.$store.state.itemRecomend_module.itemHeader
      if(resp.per_page === undefined) {
        return 1
      }
      return resp.per_page
    },
    currentPage() {
      let resp = this.$store.state.itemRecomend_module.itemHeader
      if(resp.current_page == undefined) {
        return 1
      }
      return resp.current_page
    },
    itemLastPage() {
      return this.$store.state.itemRecomend_module.itemLastPage
    },
    headers() {
      return [
        {
          text: this.$t("message.item-code"),
          align: "start",
          value: "item_code",
        },
        { text: this.$t('message.shop'), value: "from_store" },
        { text: this.$t('message.image'), value: "image_url" },
        { text: this.$t('message.name'), value: "item_name", width: "20%" },
        { text: this.$t('message.stock'), value: "stock" },
        { text: this.$t('message.price'), value: "price" },
        // { text: this.$t('message.status'), value: "status" },
        { text: this.$t('message.action'), value: "actions" },
        { text: "表示順を変更", value: "item_recom_id" }
      ]
    }
  },

};
</script>

<style scoped>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
.image-url-class {
  margin: 10px auto !important
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}
.v-application .text-start,
tr td {
  text-align: center !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.v-data-footer-top {
  border-top: none !important;
}
</style>
